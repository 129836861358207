<template>
  <div class="home-container">
    <!-- <div class="linear-bg"></div> -->
    <div class="home-scroll">
		
		<header class="sc-1c06ay3-0 gWiNFF">
			<div class="sc-1c06ay3-1 fDSyYI">
				<div class="sc-11qpg5t-0 gXrCuJ">
					<div class="sc-1onxl1x-0 hQoNeb">
						<div class="sc-1onxl1x-1 iphAvP">
							<a href="javascript:void(0);" style="height: 48PX;">
								<img style="width: 100%;height: 100%;margin-left: 2px;" src="/img/bs/logo.png">
								<div style="display:inline-block;max-width:60%;overflow:hidden;position:relative;box-sizing:border-box;margin:0">
									
									<div style="box-sizing:border-box;display:block;max-width:100%">
										<!-- <span style="font-size: 18px;color: #ec5c19;">WISE EMPLOYMENT</span> -->
										</div>
										
									
									</div>
							</a>
							
						</div>
						
					</div>
					<div class="sc-1y7y4ai-0 ifZktZ">
						<ul class="sc-1y7y4ai-1 fHbDzZ">
							<li class="sc-1y7y4ai-2 iAlXgG"><a href="javascript:void(0);">Trang chủ</a></li>
							<li class="sc-1y7y4ai-2 iAlXgG"><a href="javascript:void(0);">Bất động sản</a></li>
						</ul>
						<div class="sc-8sshey-4 bXoHQx">
							<div class="sc-8sshey-6 dkCEqR"><input type="text" value="" placeholder="Tìm kiếm" autocomplete="off" class="sc-8sshey-5 fbjppU"><span class="sc-1vo1n72-0 sc-8sshey-10 fpHzhT dfGAzM"></span></div>
						</div>
						</div>
				</div>
			</div>
		</header>
		
		<div class="sc-1b0gpch-1 dXYEMQ" style="height: auto !important;background: #fff;">
			<div class="sc-1b0gpch-2 hRxuIG">
				
				<div class="sc-11qpg5t-0 gXrCuJ">
					
					<div class="home-list-item" style="margin-top: 2px;border-radius: unset;margin-bottom: 0px;">
						<img src="/img/sy/syyn.jpg"  class="home-list-item-cover" style="height: 100%;">
						<div class="home-list-item-text">Mục thứ 5</div>
						<div class="home-list-item-text">1. Thay đổi thành ( Tập Đoàn )</div>
						<div class="home-list-item-text">Hãy xoá biểu tượng đã chỉ định</div>
					</div>
					
					<div class="sc-11qpg5t-0 sc-bljvhv-2 gXrCuJ fInUjE" style="margin-top: 8px;display:none">
						<div class="sc-bljvhv-0 eihDWA">
							<a href="javascript:void(0);" >
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 jXchzD"><span class="sc-bljvhv-5 kinNPk"></span></div>
									<div>Tài chính</div>
								</div>
							</a>
							<a href="javascript:void(0);" >
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 hOaFHN"><span class="sc-bljvhv-5 dcdmPN"></span></div>
									<div>bất động sản</div>
								</div>
							</a>
							<a href="javascript:void(0);" >
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 kwYRuX"><span class="sc-bljvhv-5 hDjaiB"></span></div>
									<div>bác sĩ</div>
								</div>
							</a>
							<a href="javascript:void(0);" >
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 jxrHGj"><span class="sc-bljvhv-5 cWvdLi" style="background-image: url(/img/sy/hh1.jpg);background-size: cover;background-position: unset;"></span></div>
									<div>giám đốc khách sạn</div>
								</div>
							</a>
							<a href="javascript:void(0);" >
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 fygvOH"><span class="sc-bljvhv-5 EZOnX"></span></div>
									<div>Dịch vụ</div>
								</div>
							</a>
							<a href="javascript:void(0);" @click="gotoMenu('/Detail6')" style="display: none;">
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 kwYRuX"><span class="sc-bljvhv-5 ducfTb"></span></div>
									<div>Đồ điện tử</div>
								</div>
							</a>
							<a href="javascript:void(0);" @click="gotoMenu('/Detail7')" style="display: none;">
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 jxrHGj"><span class="sc-bljvhv-5 gFJqoN"></span></div>
									<div><span>Điện máy, </span><span>Đồ gia dụng</span></div>
								</div>
							</a>
							<a href="javascript:void(0);" @click="gotoMenu('/Detail8')" style="display: none;">
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 fygvOH"><span class="sc-bljvhv-5 cDKJMQ"></span></div>
									<div><span>Sở thích, </span><span>Mặt hàng khác</span></div>
								</div>
							</a>
							<a href="javascript:void(0);" @click="gotoMenu('/Detail9')" style="display: none;">
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 kwYRuX"><span class="sc-bljvhv-5 fMYGMy"></span></div>
									<div><span>Thời trang, </span><span>Mỹ phẩm</span></div>
								</div>
							</a>
							<a href="javascript:void(0);" >
								<div class="sc-bljvhv-1 bpNYcO">
									<div class="sc-bljvhv-3 jxrHGj"><span class="sc-bljvhv-5 jUmBXk"></span></div>
									<div><span>Đối tác, </span><span>Cộng đồng</span></div>
								</div>
							</a>
						</div>
					</div>
					
					
					
					<div class="sc-q9qagu-15 jbsSbC" style="display: none;">
						<div class="sc-11qpg5t-0 sc-z4q3vq-3 gXrCuJ hMZfLG">
							<div class="sc-z4q3vq-4 guedfL">
								<div class="slick-slider slick-initialized" dir="ltr">
									<div class="slick-list">
										<div class="slick-track" style="width: 828px; opacity: 1; transform: translate3d(0px, 0px, 0px);">
											<div style="outline:none;display:none;" data-index="0" class="slick-slide slick-active slick-current" tabindex="-1" aria-hidden="false">
												<div>
													<div class="sc-z4q3vq-5 eQLvys">
														<div class="sc-z4q3vq-6 bgopks">
															<div style="display:block;overflow:hidden;position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;margin:0"><img alt="Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" fill="true" sizes="100vw"  src="/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"><noscript><img alt="Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ" fill="true" sizes="100vw"  src="/img/sy/1ac35176b3d54ae482c0ec2a9486a229.jpg#w=1024" decoding="async" data-nimg="fill" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" class="thumb-l" loading="lazy"/></noscript></div>
															<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div><span class="sc-1p2nuuk-1 csPPf"></span><span class="sc-1vo1n72-4 fjImPV">2</span></div>
														<div class="sc-z4q3vq-7 dpDCLd"><a href="javascript:void(0);"><span class="title">Cần tuyển kỹ thuật viên massage - nữ, 20-30 tuổi, ưa nhìn, chăm chỉ</span></a>
															<ul class="sc-z4q3vq-8 lpuxSi">
																<li class="sc-z4q3vq-12 biTXqW"></li>
															</ul><span class="price">Thỏa thuận</span></div>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
															<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
														</ul>
													</div>
												</div>
											</div>
											<div style="outline:none" data-index="1" class="slick-slide slick-active" tabindex="-1" aria-hidden="false">
												<div>
													<div class="sc-z4q3vq-5 eQLvys">
														<div class="sc-z4q3vq-6 bgopks">
															<div style="display:block;overflow:hidden;position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;margin:0"><img alt="CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI" fill="true" sizes="100vw"  src="/img/sy/5e608f5adece454ebfe371874e129589.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"><noscript><img alt="CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI" fill="true" sizes="100vw"  src="/img/sy/5e608f5adece454ebfe371874e129589.jpg#w=1024" decoding="async" data-nimg="fill" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" class="thumb-l" loading="lazy"/></noscript></div>
															<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div><span class="sc-1p2nuuk-1 csPPf"></span><span class="sc-1vo1n72-4 fjImPV">2</span></div>
														<div class="sc-z4q3vq-7 dpDCLd"><a href="javascript:void(0);"><span class="title">CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI</span></a>
															<ul class="sc-z4q3vq-8 lpuxSi">
																<li class="sc-z4q3vq-12 biTXqW"></li>
															</ul><span class="price">Thỏa thuận</span></div>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
															<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
														</ul>
													</div>
												</div>
											</div>
											<div style="outline:none" data-index="2" class="slick-slide slick-active" tabindex="-1" aria-hidden="false">
												<div>
													<div class="sc-z4q3vq-5 eQLvys">
														<div class="sc-z4q3vq-6 bgopks">
															<div style="display:block;overflow:hidden;position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;margin:0"><img alt="Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng." fill="true" sizes="100vw"  src="/img/sy/8fe2a51ea1244e768d593027a2988840.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"><noscript><img alt="Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng." fill="true" sizes="100vw"  src="/img/sy/8fe2a51ea1244e768d593027a2988840.jpg#w=1024" decoding="async" data-nimg="fill" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" class="thumb-l" loading="lazy"/></noscript></div>
															<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div><span class="sc-1p2nuuk-1 csPPf"></span></div>
														<div class="sc-z4q3vq-7 dpDCLd"><a href="javascript:void(0);"><span class="title">Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng.</span></a>
															<ul class="sc-z4q3vq-8 lpuxSi">
																<li class="sc-z4q3vq-12 biTXqW"></li>
															</ul><span class="price">Từ 10.000.000 đ/tháng</span></div>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
															<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Nam Từ Liêm, Hà Nội</span></li>
														</ul>
													</div>
												</div>
											</div>
											<div style="outline:none" data-index="3" class="slick-slide" tabindex="-1" aria-hidden="true">
												<div>
													<div class="sc-z4q3vq-5 eQLvys">
														<div class="sc-z4q3vq-6 bgopks">
															<div style="display:block;overflow:hidden;position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;margin:0"><img alt="Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm" fill="true" sizes="100vw"  src="/img/sy/category-2.png#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"><noscript><img alt="Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm" fill="true" sizes="100vw"  src="/img/sy/category-2.png#w=1024" decoding="async" data-nimg="fill" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" class="thumb-l" loading="lazy"/></noscript></div>
															<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div><span class="sc-1p2nuuk-1 csPPf"></span></div>
														<div class="sc-z4q3vq-7 dpDCLd"><a href="javascript:void(0);"><span class="title">Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm</span></a>
															<ul class="sc-z4q3vq-8 lpuxSi">
																<li class="sc-z4q3vq-12 biTXqW"></li>
															</ul><span class="price">5.000.000 - 6.500.000 đ/tháng</span></div>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
															<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
														</ul>
													</div>
												</div>
											</div>
											<div style="outline:none" data-index="4" class="slick-slide" tabindex="-1" aria-hidden="true">
												<div>
													<div class="sc-z4q3vq-5 eQLvys">
														<div class="sc-z4q3vq-6 bgopks">
															<div style="display:block;overflow:hidden;position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;margin:0"><img alt="CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM" fill="true" sizes="100vw"  src="/img/sy/cd73b959061d4e89821d3b90e889982a.jpg#w=1024" decoding="async" data-nimg="fill" class="thumb-l" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"><noscript><img alt="CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM" fill="true" sizes="100vw"  src="/img/sy/cd73b959061d4e89821d3b90e889982a.jpg#w=1024" decoding="async" data-nimg="fill" style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%" class="thumb-l" loading="lazy"/></noscript></div>
															<div class="sc-1vo1n72-1 cZhfaC"><span>Nổi bật</span></div><span class="sc-1p2nuuk-1 csPPf"></span><span class="sc-1vo1n72-4 fjImPV">2</span></div>
														<div class="sc-z4q3vq-7 dpDCLd"><a href="javascript:void(0);"><span class="title">CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM</span></a>
															<ul class="sc-z4q3vq-8 lpuxSi">
																<li class="sc-z4q3vq-12 biTXqW"></li>
															</ul><span class="price">Thỏa thuận</span></div>
														<ul class="sc-z4q3vq-8 lpuxSi">
															<li class="sc-z4q3vq-9 sc-z4q3vq-10 joVhly hqEtQl"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
															<li class="sc-z4q3vq-9 sc-z4q3vq-11 joVhly hLowUx"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
														</ul>
													</div>
												</div>
											</div>
											<div style="outline:none" data-index="5" class="slick-slide" tabindex="-1" aria-hidden="true">
												<div>
													<div class="sc-z4q3vq-1 kOWYhT"><span class="sc-1vo1n72-0 sc-z4q3vq-2 fpHzhT dLYkG"></span></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="sc-f5e146-0 cOcyJJ" style="margin-bottom: 1px;"></div>
					<!-- <div class="sc-q9qagu-4 enWxAW">
						
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/8fe2a51ea1244e768d593027a2988840.jpg" alt="Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng." class="thumb-l ls-is-cached lazyloaded" src="/img/sy/8fe2a51ea1244e768d593027a2988840.jpg">
									<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div><span class="sc-1p2nuuk-1 csPPf"></span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span>Công Ty TNHH Tân An Bình tuyển 2 nữ nhân viên kế toán bán hàng.</span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">Từ 10.000.000 đ/tháng</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Nam Từ Liêm, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/category-2.png" alt="Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm" class="thumb-l ls-is-cached lazyloaded" src="/img/sy/category-2.png">
									<div class="sc-1vo1n72-1 gCoOOb"><span>VIP S</span></div><span class="sc-1p2nuuk-1 csPPf"></span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span>Tuyển nhân viên phục vụ nhà hàng hàn quốc có phụ tiền cơm</span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">5.000.000 - 6.500.000 đ/tháng</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/8e0c3c899f7c44f7853d250747ad0ec9.jpg" alt="TUYỂN BẢO VỆ ĐI LÀM NGAY, CÓ CHỖ Ở : " class="thumb-l lazyloaded" src="/img/sy/8e0c3c899f7c44f7853d250747ad0ec9.jpg">
									<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div><span class="sc-1p2nuuk-1 csPPf"></span><span class="sc-1vo1n72-4 fjImPV">3</span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span>TUYỂN BẢO VỆ ĐI LÀM NGAY, CÓ CHỖ Ở : </span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">6.840.000 - 10.000.000 đ/tháng</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/2329291cd6aa4981a48a54037fd95594.jpg" alt=" tuyển gấp nhân viên lái xe và phụ xe chạy tuyến cố định từ sân bay về" class="thumb-l lazyloaded" src="/img/sy/2329291cd6aa4981a48a54037fd95594.jpg">
									<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div><span class="sc-1p2nuuk-1 csPPf"></span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span> tuyển gấp nhân viên lái xe và phụ xe chạy tuyến cố định từ sân bay về</span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">10.000.000 - 12.000.000 đ/tháng</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/b5ff8195a1ac4add93388a5d6001ab37.jpg" alt="Tuyển kỹ thuật máy tính máy in lương cao. Chưa biết nghề được đào tạo." class="thumb-l lazyloaded" src="/img/sy/b5ff8195a1ac4add93388a5d6001ab37.jpg">
									<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div><span class="sc-1p2nuuk-1 csPPf"></span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span>Tuyển kỹ thuật máy tính máy in lương cao. Chưa biết nghề được đào tạo.</span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">Thỏa thuận</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Cầu Giấy, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/5e608f5adece454ebfe371874e129589.jpg" alt="CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI" class="thumb-l ls-is-cached lazyloaded" src="/img/sy/5e608f5adece454ebfe371874e129589.jpg">
									<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div><span class="sc-1p2nuuk-1 csPPf"></span><span class="sc-1vo1n72-4 fjImPV">2</span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span>CẦN TUYỂN: 10 NAM  LAO ĐỘNG PHỔ THÔNG TẠI HOÀNG MAI</span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">Thỏa thuận</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/cd73b959061d4e89821d3b90e889982a.jpg" alt="CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM" class="thumb-l ls-is-cached lazyloaded" src="/img/sy/cd73b959061d4e89821d3b90e889982a.jpg">
									<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div><span class="sc-1p2nuuk-1 csPPf"></span><span class="sc-1vo1n72-4 fjImPV">2</span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span>CẦN TUYỂN: 05 THỢ HÀN CÓ KINH NGHIỆM</span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">Thỏa thuận</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/e7dabb0252214661b987c004d4fbdf8b.jpg" alt="Long Biên- CTY ONG VÀNG TUYỂN GIAO HÀNG THEO XE Ô TÔ" class="thumb-l lazyloaded" src="/img/sy/e7dabb0252214661b987c004d4fbdf8b.jpg">
									<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div><span class="sc-1p2nuuk-1 csPPf"></span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span>Long Biên- CTY ONG VÀNG TUYỂN GIAO HÀNG THEO XE Ô TÔ</span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">Từ 10.000.000 đ/tháng</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Long Biên, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/d2b168e9a2a9480098f0fad25fde8789.jpg" alt="Tuyển nhân viên massage tẩm quất, làm tại 68 Cầu Bươu, Thanh Trì, HN" class="thumb-l lazyloaded" src="/img/sy/d2b168e9a2a9480098f0fad25fde8789.jpg">
									<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div><span class="sc-1p2nuuk-1 csPPf"></span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span>Tuyển nhân viên massage tẩm quất, làm tại 68 Cầu Bươu, Thanh Trì, HN</span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">Thỏa thuận</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Huyện Thanh Trì, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="sc-q9qagu-5 kNNcTy">
							<div class="sc-q9qagu-3 bsQfQa">
								<div class="sc-q9qagu-7 kqqkWq"><img data-src="/img/sy/29c38ddca68f483b844027998f1bf7a7.jpg" alt="Tuyển gấp: Thợ xén, Thợ máy chần, Thợ máy bằng, Quản lý sản xuất..." class="thumb-l lazyloaded" src="/img/sy/29c38ddca68f483b844027998f1bf7a7.jpg">
									<div class="sc-1vo1n72-1 jrxHxK"><span>VIP 1</span></div><span class="sc-1p2nuuk-1 csPPf"></span></div>
								<div class="sc-q9qagu-14 eOzaio">
									<div class="sc-q9qagu-8 dGTvSk"><a class="title" href="javascript:void(0);"><span>Tuyển gấp: Thợ xén, Thợ máy chần, Thợ máy bằng, Quản lý sản xuất...</span></a>
										<ul class="sc-q9qagu-9 bIsYGD"></ul><span class="price">Thỏa thuận</span></div>
									<ul class="sc-q9qagu-9 bIsYGD">
										<li class="sc-q9qagu-11 sc-q9qagu-12 geNyUe ijSNSa"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT gVZxfc"></span><span>Hôm nay</span></li>
										<li class="sc-q9qagu-11 sc-q9qagu-13 geNyUe hYvkOS"><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT bGiBvb"></span><span>Quận Hoàng Mai, Hà Nội</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				 -->
				 <div class="home-list">
					<div class="home-list-item">
						<img src="/img/sy/sye1.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Recap Hội thảo giải pháp quốc tế hoá doanh nghiệp và đầu tư Thuỵ Sĩ</div>
						<div class="home-list-item-text">Kết thúc buổi Hội thảo giải pháp quốc tế hóa doanh nghiệp và đầu tư Thuỵ Sĩ vào lúc 12h ngày 02/11/2024. Chúng tôi trân trọng gửi lời cảm ơn chân thành đến quý khách hàng đã đến tham dự</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye2.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Private Seminar Pathway to Australia</div>
						<div class="home-list-item-text">Chúng tôi trân trọng thông báo sự kiện đặc biệt dành cho các nhà đầu tư sắp diễn ra tại Văn phòng SI Group Hà Nội và Hồ Chí Minh với sự tham gia của khách mời đến từ Úc:</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye3.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Đại diện Ngân hàng Standard Chartered đến thăm Văn phòng SI Group Hà Nội</div>
						<div class="home-list-item-text">Đại diện Ngân hàng Standard Chartered đến thăm Văn phòng SI Group Hà Nội Ngày 14/11/2024, Đại diện Ngân hàng Standard Chartered, ông Arif Shezad – Giám đốc Toàn cầu, Khối Khách hàng Cá nhân đến thăm Văn phòng SI</div>
						
					</div>
					<div class="home-list-item" style="display: none;">
						<img src="/img/sy/sye4.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Đại diện Ngân hàng Standard Chartered đến thăm Văn phòng SI Group Hà Nội</div>
						<div class="home-list-item-text">Đại diện Ngân hàng Standard Chartered đến thăm Văn phòng SI Group Hà Nội Ngày 14/11/2024, Đại diện Ngân hàng Standard Chartered, ông Arif Shezad – Giám đốc Toàn cầu, Khối Khách hàng Cá nhân đến thăm Văn phòng SI</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye5.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Cập nhật sửa đổi Mẫu đơn I-129 mới nhất 2025 dành cho lao động nước ngoài diện không định cư</div>
						<div class="home-list-item-text">Sở Di trú và Nhập tịch Mỹ (USCIS) sẽ thực hiện cải tổ quan trọng đối với Mẫu đơn I-129 (Đơn thỉnh nguyện dành cho lao động nước ngoài không định cư) kể từ ngày 17/01/2025. Mẫu đơn I-129 đóng</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye6.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Điểm lại 4 thách thức lớn đối với các nhà đầu tư định cư nước ngoài năm 2024</div>
						<div class="home-list-item-text">Năm 2024 là một trong những năm có nhiều sự kiện lịch sử nhất đối với các nhà đầu tư định cư nước ngoài. Chúng tôi đã cập nhật rất nhiều thông tin mới cùng các xu hướng thay đổi</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye7.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Private Seminar Pathway to Australia</div>
						<div class="home-list-item-text">10/11/2024</div>
						<div class="home-list-item-text">Hà Nội - Hồ Chí Minh</div>
						<div class="home-list-item-text">SI Group trân trọng thông báo Hội thảo Pathway to Australia sắp diễn ra vào ngày 10/11/2024 và 16/11/2024 với sự tham gia của đối tác đến từ Úc.</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye8.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Hội thảo Hành trình an cư Úc tại TP. Hồ Chí Minh</div>
						<div class="home-list-item-text">19/10/2024</div>
						<div class="home-list-item-text">SI Group Hồ Chí Minh</div>
						<div class="home-list-item-text">SI Group trân trọng thông báo Hội thảo Hành trình an cư Úc sắp diễn ra vào ngày 19/10/2024 với sự tham gia của quý đối tác Đông Tây Land</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye9.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Hội thảo Hành trình an cư Úc tại TP. Hồ Chí Minh</div>
						<div class="home-list-item-text">19/10/2024</div>
						<div class="home-list-item-text">SI Group Hồ Chí Minh</div>
						<div class="home-list-item-text">SI Group trân trọng thông báo Hội thảo Hành trình an cư Úc sắp diễn ra vào ngày 19/10/2024 với sự tham gia của quý đối tác Đông Tây Land</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye10.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Hội thảo lao động định cư Úc – Mỹ: Cơ hội vàng giữa cơn khát nguồn nhân lực</div>
						<div class="home-list-item-text">11/05/2024</div>
						<div class="home-list-item-text">Minh Toan Galaxy Hotel, 306 Đường 2 Tháng 9, Hoà Cường Bắc, Hải Châu, Đà Nẵng</div>
						<div class="home-list-item-text">Thấu hiểu niềm khao khát hiện thực hóa giấc mơ di trú của quý vị, SI Group trân trọng thông báo Hội thảo định cư Úc và Mỹ đặc biệt lớn sắp diễn ra</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye11.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">Hội thảo lao động định cư Úc – Mỹ: Cơ hội vàng giữa cơn khát nguồn nhân lực</div>
						<div class="home-list-item-text">11/05/2024</div>
						<div class="home-list-item-text">Minh Toan Galaxy Hotel, 306 Đường 2 Tháng 9, Hoà Cường Bắc, Hải Châu, Đà Nẵng</div>
						<div class="home-list-item-text">Thấu hiểu niềm khao khát hiện thực hóa giấc mơ di trú của quý vị, SI Group trân trọng thông báo Hội thảo định cư Úc và Mỹ đặc biệt lớn sắp diễn ra</div>
					</div>
					<div class="home-list-item">
						<img src="/img/sy/sye12.jpg"  class="home-list-item-cover">
						<div class="home-list-item-text">[Recap] Tọa đàm gặp gỡ trực tiếp Thị trưởng thành phố Port Hedland</div>
						<div class="home-list-item-text">Tại buổi Tọa đàm gặp gỡ trực tiếp Thị trưởng thành phố Port Hedland, quý khách không chỉ được nghe giới thiệu về vùng đất mới mà còn là cơ hội an cư lạc nghiệp.</div>
					</div>
				 </div>
				</div>
			</div>
			<div class="sc-1b0gpch-3 ifmEXn" style="height: auto !important;display: none;">
				<div>
					<div class="sc-3bovxq-0 jrPEep">
						<div>Ngành nghề</div>
						<ul class="sc-3bovxq-1 cHIamR">
							<li><a href="javascript:void(0);">Bán hàng</a></li>
							<li><a href="javascript:void(0);">Người giúp việc, tạp vụ</a></li>
							<li><a href="javascript:void(0);">Công nhân</a></li>
							<li><a href="javascript:void(0);">Lái xe, phụ xe</a></li>
							<li><a href="javascript:void(0);">Kế toán, thu ngân</a></li>
							<li><a href="javascript:void(0);">An ninh, bảo vệ</a></li>
						</ul><button class="sc-3bovxq-2 eAIMNB"><span>Xem thêm</span><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT fKCVLE"></span></button></div>
					<div class="sc-3bovxq-0 jrPEep">
						<div>Quận/Huyện</div>
						<ul class="sc-3bovxq-1 cHIamR">
							<li><a href="javascript:void(0);">Quận Ba Đình</a></li>
							<li><a href="javascript:void(0);">Quận Bắc Từ Liêm</a></li>
							<li><a href="javascript:void(0);">Quận Cầu Giấy</a></li>
							<li><a href="javascript:void(0);">Quận Đống Đa</a></li>
							<li><a href="javascript:void(0);">Quận Hà Đông</a></li>
							<li><a href="javascript:void(0);">Quận Hai Bà Trưng</a></li>
						</ul><button class="sc-3bovxq-2 eAIMNB"><span>Xem thêm</span><span class="sc-1vo1n72-0 sc-1f1ueip-0 fpHzhT fKCVLE"></span></button></div>
				</div>
				<div class="sc-1387a10-0 cNFLhZ">
					<div class="sc-ri4pcv-0 bfKsqJ">
						<ins class="adsbygoogle" data-ad-client="ca-pub-3000426983537206" data-ad-slot="7446036210" data-ad-format="auto" data-full-width-responsive="true" style="display: block; transition: none 0s ease 0s; outline: none; border: none; padding: 0px; border-spacing: 0px; margin-left: -16px; width: 414px; z-index: 30; height: 345px;" data-adsbygoogle-status="done" data-ad-status="filled"><div id="aswift_1_host" tabindex="0" title="Advertisement" aria-label="Advertisement" style="border: none; height: 345px; width: 414px; margin: 0px; padding: 0px; position: relative; visibility: visible; background-color: transparent; display: inline-block; overflow: visible;"><iframe id="aswift_1" name="aswift_1" browsingtopics="true" style="left:0;position:absolute;top:0;border:0;width:414px;height:345px;" sandbox="allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation-by-user-activation" width="414" height="345" frameborder="0" marginwidth="0" marginheight="0" vspace="0" hspace="0" allowtransparency="true" scrolling="no" allow="attribution-reporting" src="" data-google-container-id="a!2" data-google-query-id="CK7vrdiAgoEDFQHEFgUdFzgNRQ" data-load-complete="true"></iframe></div></ins></div>
				</div>
			</div>
		</div>
		
		
		
		
		
      <div class="banner" style="display: none;">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar" style="display: none;">
        <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            background="#ffffff"
            color="#F905E5"
            :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div>
      <div class="hot-game" style="display: none;">
        <div class="hot-title-div">
          <div>
            <span>{{ $t("推荐任务") }}</span></div>
          <div @click="gotoMenu('/Game')">
            <span>{{ $t("查看更多") }}</span>
            <van-icon name="arrow" color="#979799" />
          </div>
        </div>
        <div class="hot-items-div">
          <van-grid :border = false :column-num="4" :icon-size="20">
            <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{v.name}}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="hot-recommend">
          <div class="hot-title-div" style="display: none;">
            <div>
              <span>{{ $t("人气排行") }}</span></div>
            <div>
              <span @click="gotoMenu('/Video')">{{ $t("查看更多") }}</span>
              <van-icon name="arrow" color="#979799" />
            </div>
          </div>
          <div class="movie_list_0" style="display: none;">
            <swiper class="movie_swiper" :options="movielistSwiperOption">
              <swiper-slide v-for="(v,key) in movielist_0" :key="key" >
                <van-image class="movie_cover" @click="toPlayVideo(v.id)"  round :src="v.cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.title}}</span>
                    <div class="van-count-down">{{v.time}}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="hot-title-div" style="display: none;">
            <div>
              <span>{{ $t("最新职位") }}</span></div>
            <div @click="gotoMenu('/Video')">
              <span>{{ $t("查看更多") }}</span>
              <van-icon name="arrow" size="25" color="#979799" />
            </div>
          </div>
          <div class="movie_list_1 latest_jobs" style="display: none;">
            <div class=" latest_item" v-for="(v,key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
              <!-- <van-image class="cover_img"  round :src="v.cover">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <div class="movie-list-item-bottom">
                <div class="movie-time-div">
                  <span>{{v.title}}</span>
                  <span>{{ $t("播放") }}:{{v.count}}</span>
                </div>
              </div> -->
			  
			  <!-- <div class="latest_item" > -->
			  	<div class="item_name_line"> <a  class="item_name substring font15">{{v.title}}</a>
			  		<div class="clear"></div>
			  		<div class="item_wage font13">{{v.vod_yx}}/{{ $t("月") }}</div>
			  	</div>
			  	<div class="item_some_line">
			  		<div class="item_some substring font12 sssb">{{v.cname}}|{{v.vod_gsjj}}</div>
			  		<div class="item_date font12">{{v.create_time}}</div>
			  	</div>
			  	<div class="item_tag">
			  		<div class="tag_cell" v-for="(vv,keyy) in v.vod_fldy" :key="keyy">{{vv}}</div>
			  		<!-- <div class="tag_cell">包食宿</div>
			  		<div class="tag_cell">有年假</div>
			  		<div class="tag_cell">技术培训</div> -->
			  		<div class="clear"></div>
			  	</div>
			  	<div class="split_line"></div>
			  	<div class="item_company_line"> <a  class="company_name substring font12">{{v.vod_gsmc}}</a>
			  		<div class="company_auth"></div>
			  		<div class="clear"></div>
			  	</div>
				<div class="split_line1"></div>
			  <!-- </div> -->
			  
            </div>
            <div class="hot-recommend-more" @click="gotoMenu('/Video')">{{ $t("查看更多") }}</div>
          </div>
		  
		 <!-- <div style="margin-top: 50px;">
			  <img src="img/login/syyy.png"  style="width:100%;height: 100%;">
		  </div> -->
		  
		  
        </div>
      </van-pull-refresh>
    </div>
	
	<div class="call-mobile" style="">
		<a class="" href="tel:0348148888"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0348.14.8888</a>
	</div>
	
	<div class="call-mobile" style="display:none;">
		<a class="" href="tel:0327089999"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0327.08.9999</a>
	</div>
	
	<!-- <div class="call-mobiley">
		<a class="" href="https://kefu.bybit01.vip/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=2&groupid=1&special=2"> <img src="/img/gw/kf.png" alt="phone" title="phone"></a>
	</div> -->
	
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("获取中"),
      banners: [{}],
      basicData:[],
      gameitem: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [{},{},{},{},{},{},{},{}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed:800,
        autoplay:true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      }
    };
  },
  methods:{
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t("刷新成功"));
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
      })

    },
    getNotice(data){
      this.notice = data.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data.movielist_1
    },
    getBanner(data){
      this.banners = data.banners;
    }
  },
  mounted () {

  },
  created() {
    this.getBasicConfig();
  }
}

</script>
<style src='../../assets/css/sy1.css' scoped></style>
<style src='../../assets/css/sy.css' scoped></style>

<style lang='less' scoped>
	/* @import "../../assets/css/sy.css"; */
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.linear-bg{
  height: 200px;
  background: linear-gradient(270deg,#9e9ce7,#500cfd);
  /* background: linear-gradient(270deg,#e6c3a1,#f905e5); */
}



.home-container{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(245, 247, 249);
  /* background-color: #ffffff; */
}
.linear-gradient{
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}
::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width:40px;
}
.notice-swipe{
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner{
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game{
  width: 100%;
  height:  100%;
}
.hot-title-div{
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div> div:first-child{
  width: 130px;
}
.hot-title-div div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div>div:nth-child(2) span{
  font-size: 20px;
  color: #979799;
}
.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}
.hot-title-div > div:nth-child(2) span{
  font-size: 25px;
  color: #979799;
}
.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #f905e5;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div{
  margin-top: -3px;
}
.hot-game .hot-items-div span{
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}
.hot-recommend{
  width: 100%;
  flex: 1;
  background-color: #fff;
}
.movie_swiper{
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0{
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover{
  border-radius: 10px;
  width:550px;
  height:330px
}
.movie_list_0 .movie-list-item-bottom{
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius:  0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span{
  font-size: 30px;
}
.movie_list_1{
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img{
  border-radius: 10px;
  width:335px;
  height:290px;
}
.home-scroll{
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item{
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;

}
.movie_list_1 .movie-list-item:nth-child(odd){
  margin-right: 25px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right:110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more{
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}
.hot-items-div .game_item_img{
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

.hot-title-div>div:first-child {
    width: 50%;
}


.latest_item {
    position: relative;
    width: 100%;
    background: #FFFFFF;
    margin-bottom: 0.08rem;
    padding: 1rem 1rem 0 1rem;
}

.latest_item .item_name_line {
    position: relative;
    height: 1.6rem;
    line-height: 1.6rem;
}
.latest_item .item_name_line .item_name {
    display: block;
    float: left;
    margin-right: .15rem;
    /* max-width: 4.2rem; */
    height: 1.6rem;
    line-height: 1.6rem;
    text-decoration: none;
    color: #333333;
    font-weight: 500;
}
.font9{font-size:.1888rem}
.fontag{font-size:.22rem}
.font10{font-size:.234rem}
.font12{font-size:.25714285rem}
.font13{font-size:1rem}
.font14{font-size:.3rem}
.font15{font-size:1.3rem}
.font16{font-size:.34285714rem}
.font18{font-size:.38571428rem}
.font20{font-size:.468rem}
.font24{font-size:.56249999rem}
.font28{font-size:.65624999rem}
.substring{overflow:hidden;/* white-space:nowrap; */text-overflow:ellipsis;}
.clear{clear:both;height:0;font-size:0;line-height:0;}
.latest_item .item_name_line .item_wage {
    position: absolute;
    right: 0;
    top: 0;
    height: .6rem;
    line-height: .6rem;
    color: #ffaf24;
    font-weight: 500;
}
.latest_item .item_some_line {
    position: relative;
    height: 1.6rem;
    line-height: 1.8rem;
    margin-bottom: 0.8rem;
}
.latest_item .item_some_line .item_date {
    position: absolute;
    right: 0;
    top: 0;
    height: .6rem;
    line-height: .6rem;
    color: #999999;
}
.latest_item .item_some_line .item_date {
    position: absolute;
    right: 0;
    top: 0;
    height: .6rem;
    line-height: .6rem;
    color: #999999;
}
.latest_item .item_tag {
    padding-bottom: 1rem;
}
.latest_item .item_tag .tag_cell {
    float: left;
    line-height: 1;
    padding: .1rem .2rem;
    margin-right: .15rem;
    font-size: 0.8rem;
    background: #ecf5ff;
    color: #1787fb;
}
.latest_item .split_line {
    width: 100%;
    border-bottom: 1px dashed #f3f3f3;
}
.latest_item .split_line1 {
    width: 100%;
	background-color: #efe9e9;
	height: 3px;
    /* border-bottom: 1px dashed #f3f3f3; */
}
.latest_item .item_company_line {
    position: relative;
    height: 1.9rem;
    line-height: 1.9rem;
}
.latest_item .item_company_line .company_name {
    display: block;
    float: left;
    margin-right: .15rem;
    /* max-width: 5.5rem; */
    height: 1.9rem;
    line-height: 1.9rem;
    text-decoration: none;
    color: #666666;
}
.latest_item .item_company_line .company_auth {
    display: block;
    float: left;
    width: 1.29rem;
    height: 1.9rem;
    background: url("/img/new/company_auth.png") 0 center no-repeat;
    background-size: 1.29rem;
    margin-right: .1rem;
}
.latest_jobs {
    width: 100%;
    margin-bottom: .15rem;
}
/* *{ font-family:"Microsoft YaHei",\5FAE\8F6F\96C5\9ED1,"微软雅黑";font-size:12px;} */
.sssb{
	max-width: 27.2rem;
	    height: 1.6rem;
}

.call-mobile {
    background: #e5332d;
    position: fixed;
    bottom: 70PX;
    height: 40PX;
    line-height: 40PX;
    padding: 0 20PX 0 0PX;
    border-radius: 40PX;
    color: #fff;
    left: 10PX;
    z-index: 6;
    width: 200PX;
    text-align: center;
}
.call-mobile a {
    color: #fff;
    font-size: 18PX;
    font-weight: bold;
    text-decoration: none;
    white-space: nowrap;
}
.call-mobile img {
    background-color: #ad0202;
    border-radius: 39PX;
    float: left;
    width: 39PX;
    height: 39PX;
    margin-right: 10PX;
}
.call-mobiley {

    position: fixed;
    bottom: 70PX;
    height: 40PX;
    line-height: 40PX;
    padding: 0 20PX 0 0PX;
    border-radius: 40PX;
    color: #fff;
    left: 300PX;
    z-index: 6;
    width: 0PX;
    text-align: center;
}
.call-mobiley a {
    color: #fff;
    font-size: 18PX;
    font-weight: bold;
    text-decoration: none;
    white-space: nowrap;
}
.call-mobiley img {
    background-color: #ffffff;
    border-radius: 39PX;
    float: left;
    width: 45PX;
    height: 45PX;
    margin-right: 10PX;
}
.home-scroll{
	background-color: #fff;
}
.home-list{
	padding: 20px;
}
.home-list-item{
	background-color: #eee;
	border-radius: 20px;
	overflow: hidden;
	padding-bottom: 20px;
	margin-bottom: 30px;
}
.home-list-item-cover{
	width: 100%;
	height: 420px;
	object-fit: cover;
}
.home-list-item-text{
	margin-top: 10px;
	padding: 0 20px;
}
</style>
