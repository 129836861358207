<template>
  <div class="bg-container page">
      <!-- <img class="bg-img" src="img/login/login-bg.png"> -->
      <div class="bg-wrapper">
        <div class="login">
          <van-nav-bar class="nav-bar">
            <template #left>
              <van-icon name="arrow-left" color="#fff" @click="back()"/>
            </template>
			<template #right>
				<!-- <div class="language" @click="tolg()"><img  :src="'img/'+lg+'.png'"></div> -->
			</template>
			
			
          </van-nav-bar>
          <div class="wrapper">
			   <div class="title" style="font-size: 12vw; margin-top: 30px; color:#ff968f">SI GROUP</div>
			<div class="loginFormy">
			<div class ="loginformt">
			<span>{{ $t("登录") }}</span>
			</div>	
			<div @click="toRegister()" class ="loginformu">
			<span>{{ $t("注册") }}</span>	
			</div>	
			</div>
			
            <div class="loginForm">
				<div class="loginForlogo">
					ĐĂNG NHẬP TÀI KHOẢN
					</div>
             <van-field v-model="username"  class="input" :placeholder="$t('请输入用户名')" />
        
			  <van-field
                  v-model="password"
                  :type="passwordType"
                  
                  class="inputy"
                  :placeholder="$t('请输入密码')">
                <template slot="right-icon">
                  <van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType"/>
                </template>
              </van-field>
              <div class="reset-text">
                <span>{{ $t("忘记密码？") }}</span>
              </div>
              
              <van-button class="login-btn"   type="primary" size="normal" @click="doLogin()">{{ $t("登录") }}</van-button>
            
			<div @click="toRegister()" class="register-text">
              <span>{{ $t("没有账户？马上注册")}}</span>
			   <div class="zc">{{ $t("注册") }}
			  
			  </div>
            </div>
			</div>
		<!-- <div class="dbtp">
			<div class="logo-im">
			<img class="im" src="img/login/1.png">	
			<img class="im" src="img/login/2.png">	
			<img class="im" src="img/login/3.png">	
			<img class="im" src="img/login/4.png">	
			<img class="im" src="img/login/5.png">	
			<img class="im" src="img/login/6.png">	
			<img class="im" src="img/login/7.png">	
			<img class="im" src="img/login/8.png">	
			<img class="im" src="img/login/9.png">	
			<img class="im" src="img/login/10.png">	
			<img class="im" src="img/login/11.png">	
			<img class="im" src="img/login/12.png">	
				</div>
			</div> -->
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      username: '',
      password: this.inputValue,
      passwordType: 'password',
	  lg:this.$i18n.locale,
    };
  },
  methods: {
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back(){
      return window.history.back();
    },
    toRegister(){
      this.$router.push("Register")
    },
	tolg(){
	  this.$router.push("Language")
	},
    doLogin() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast(this.$t('请输入用户名！'));
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast(this.$t("请输入密码"));
        return false;
      }
      this.$http({
        method: 'get',
        data:{username:this.username,password:this.password},
        url: 'member_login'
      }).then(res=>{
          if(res.code === 200){
            this.$toast.success(res.msg);
            localStorage.setItem('token',res.data.id)
            this.$router.push("Mine")
          }else {
            this.$toast(res.msg);
          }
      })
    }
  },
  created() {
      if(localStorage.getItem('token')){
        return window.history.back();
      }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.language {
    position: absolute;
    top: 0.533vw;
    right: 0;
    height: 10.667vw;
}

.language img{
    height: 100%;
}

.login{
  height: 100%;
   justify-content: center;
}
.bg-container .bg-wrapper .login .nav-bar{
  background: 0 0
  
}

.login .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  
}
.logo-container{
  margin: 0 auto;
  width: 45%;
}
.logo-container .logo-wrapper{
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.login .wrapper .title{
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.login .wrapper .loginForm{
  padding: 20px;
  margin: 20px 20px;
  border-radius: 30px;
  background: #fff;
}
.login .wrapper .loginForm .loginForlogo{
  padding: 20px;
  margin: 20px 20px;
  border-radius: 30px;
  text-align: center;
  color: rgb(255, 150, 143);
  font-size: 1.3rem;
}
.login .wrapper .loginForm .input{
  padding: 10px 20px;
  margin-top: 40px;
  border-radius: 20px;

  line-height: 80px;
  font-size: 30px;
  color: #4e4e4e;
  border: 1px solid #a8a8a8;


}
.login .wrapper .loginForm .inputy{
  padding: 10px 20px;
  margin-top: 40px;
  border-radius: 20px;

  line-height: 80px;
  font-size: 30px;
  color: #4e4e4e;
  border: 1px solid #a8a8a8
}

.login .wrapper .loginFormy{
     width: 21rem;
	 height: 4rem;
     justify-content: center;
     margin: 0 auto 3rem;
	 font-size: 1.3rem;
     border: none;
	 margin-top: 20px;
     border-radius: 3rem;
     background-color: #ff968f;
  justify-content: center;
  display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.login .wrapper .loginFormy .loginformt{
     width: 10rem;
	 height: 3rem;
	 margin-top: 0.5rem;
     justify-content: center;
     border-radius: 3rem;
     background-color: #ffffff;
display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
	  list-style: none;
align-items: center;
}
.login .wrapper .loginFormy .loginformu{
     width: 10rem;
	 height: 3rem;
	 margin-top: 0.5rem;
     justify-content: center;
     border-radius: 3rem;
	 color: #fff;

display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
	  list-style: none;
align-items: center;
}

::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.login .wrapper .loginForm .reset-text{
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login .wrapper .loginForm .reset-text span{
	padding-top: 20px;
  color: #2f2f2f;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.login .wrapper .loginForm .register-text{
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .wrapper .loginForm .register-text .zc{
	color: #ff5500;
}
	
.login .wrapper .loginForm .register-text span{
  color: #000;
  font-size: 0.85417rem;
  font-weight: 500;
  line-height: 20px;
}
.im{
width: 230px;
height: 40px;
padding-left: 30px;
}
.login .wrapper .loginForm .login-btn{
 /* margin-top: 85px; */
  width: 50%;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  background: #ff5500;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  margin-left: 25%;
  
}

</style>
